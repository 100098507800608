<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-22 09:52:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:31:18
 * @FilePath: /mediatom-web/src/components/CreativeForm/AdxFlowForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-flow-form-container">
    <!-- 亏损设置 -->
    <a-form-model-item prop="shieldRuleVo.lossSwitch" label="亏损设置">
      <a-switch size="small" :checked="formquery.lossSwitch === 1" @click="handleChangeSwitch('lossSwitch')"></a-switch>
    </a-form-model-item>
    <template v-if="+formquery.lossSwitch">
      <a-form-model-item
        prop="shieldRuleVo.lossRatio"
        label="亏损比例"
        :rules="[{ required: true, message: '请输入亏损比例', trigger: 'blur' }]"
      >
        <a-input type="number" :min="0" :max="100" v-model.number.trim="formquery.lossRatio" placeholder="请输入亏损比例">
          <span class="percent-text" slot="suffix">%</span>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="亏损时间设置">
        <div class="time-box">
          <a-time-picker
            :allowClear="false"
            @change="
              (time, timeStr) => {
                cahngeTime(time, timeStr, 'lossStartHour')
              }
            "
            :default-value="moment(this.formquery.lossStartHour || '00:00', 'HH:mm')"
            format="HH:mm"
          />
          <span style="margin-left: 10px"></span>
          <a-time-picker
            :allowClear="false"
            @change="
              (time, timeStr) => {
                cahngeTime(time, timeStr, 'lossEndHour')
              }
            "
            :default-value="moment(this.formquery.lossEndHour || '00:00', 'HH:mm')"
            format="HH:mm"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item label="重设时间设置" prop="shieldRuleVo.lossChongsheHour">
        <a-time-picker
          :allowClear="false"
          @change="
            (time, timeStr) => {
              cahngeTime(time, timeStr, 'lossChongsheHour')
            }
          "
          :default-value="moment(this.formquery.lossChongsheHour || '00:00', 'HH:mm')"
          format="HH:mm"
        />
      </a-form-model-item>
    </template>
    <!-- UA过滤控制 -->
    <a-form-model-item prop="shieldRuleVo.uaValidFilterSwitch" label="UA过滤控制">
      <a-switch
        size="small"
        :checked="formquery.uaValidFilterSwitch === 1"
        @click="handleChangeSwitch('uaValidFilterSwitch')"
      ></a-switch>
    </a-form-model-item>
    <a-form-model-item
      v-if="+formquery.uaValidFilterSwitch === 1"
      prop="shieldRuleVo.uaValidRegular"
      label="UA字符串"
      :rules="[{ required: true, message: '请输入UA字符串', trigger: 'blur' }]"
    >
      <a-input v-model.trim="formquery.uaValidRegular" placeholder="请输入UA字符串"> </a-input>
    </a-form-model-item>
    <!-- 人群包白名单 -->
    <a-form-model-item prop="shieldRuleVo.crowdSwitch" label="人群包白名单">
      <a-switch size="small" :checked="formquery.crowdSwitch === 1" @click="handleChangeSwitch('crowdSwitch')"></a-switch>
    </a-form-model-item>
    <template v-if="+formquery.crowdSwitch === 1">
      <a-form-model-item prop="shieldRuleVo.targetCrowdType" label="名单类型">
        <a-radio-group v-model="formquery.targetCrowdType">
          <a-radio :value="1"> 黑名单 </a-radio>
          <a-radio :value="2"> 白名单 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="shieldRuleVo.targetCrowds" label="名单">
        <m-select-more
          style="width: 100%"
          v-model="formquery.targetCrowds"
          :allData="crowdList"
          :searchById="true"
          :hasIcon="false"
          :showId="false"
          label="人群包名单"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
          :showLabel="false"
        />
      </a-form-model-item>
    </template>
    <!-- 日期定向 -->
    <a-form-model-item prop="shieldRuleVo.dateSwitch" label="日期定向">
      <a-switch size="small" :checked="formquery.dateSwitch === 1" @click="handleChangeSwitch('dateSwitch')"></a-switch>
    </a-form-model-item>
    <a-form-model-item label="日期范围" v-if="+formquery.dateSwitch === 1">
      <a-range-picker
        :allowClear="false"
        :default-value="[
          moment(formquery.startDate || initDate(1, 0).end, dateFormat),
          moment(formquery.endDate || initDate(1, 0).end, dateFormat)
        ]"
        :format="dateFormat"
        @change="changeDate"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import moment from 'moment'
import mixDate from '@/mixins/initDate'
export default {
  name: 'AdxFlowForm',
  mixins: [mixDate],
  data () {
    return {
      dateFormat: 'YYYY-MM-DD'
    }
  },
  created () {
    // 亏损设置
    this.$set(this.formquery, 'lossSwitch', this.value.lossSwitch || 0)
    this.$set(this.formquery, 'lossRatio', this.value.lossRatio || undefined)
    this.$set(this.formquery, 'lossStartHour', this.value.lossStartHour || '00:00')
    this.$set(this.formquery, 'lossEndHour', this.value.lossEndHour || '00:00')
    this.$set(this.formquery, 'lossChongsheHour', this.value.lossChongsheHour || '00:00')
    // UA设置
    this.$set(this.formquery, 'uaValidFilterSwitch', this.value.uaValidFilterSwitch || 0)
    this.$set(this.formquery, 'uaValidRegular', this.value.uaValidRegular || undefined)
    // 日期定向
    this.$set(this.formquery, 'dateSwitch', this.value.dateSwitch || 0)
    this.$set(this.formquery, 'startDate', this.value.startDate || this.initDate(1, 0).end)
    this.$set(this.formquery, 'endDate', this.value.endDate || this.initDate(1, 0).end)
    // 人群包白名单
    this.$set(this.formquery, 'crowdSwitch', this.value.crowdSwitch || 0)
    this.$set(this.formquery, 'targetCrowdType', this.value.targetCrowdType || 1)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    crowdList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    moment,
    handleChangeSwitch (key) {
      this.formquery[key] = +!this.formquery[key]
    },
    cahngeTime (time, timeStr, key) {
      this.formquery[key] = timeStr
    },
    changeDate (date, dateStr) {
      this.formquery.startDate = dateStr[0] || undefined
      this.formquery.endDate = dateStr[1] || undefined
    }
  }
}
</script>

<style lang="less" scoped>
</style>
